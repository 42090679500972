#nossa-historia {
    padding: 0 0 0 var(--padding-interno-horizontal-p);
    background-color: var(--esmeralda);
    height: fit-content;
    display: inline-flex;
    background-color: var(--agua-marinha);
    background-image: url(../assets/nossahistoria_background.png);
    background-size: cover;
    background-position: right;
    background-attachment: scroll;
    color: var(--branco);
}

#area-textos-nossa-historia {
    width: 60%;
    padding: var(--padding-vertical-g) 0 var(--padding-vertical-p) 0;
    text-align: justify;
    margin-bottom: var(--padding-vertical-p);
}


#linha-vertical-nossa-historia {
    min-height: 100%;
    width: 0.3em;
    margin-right: 1.5em;
    background-color: var(--preto);
}

@media (max-width: 639px) {

    #nossa-historia {
        background-size: cover;
        background-position: 60% ;
    }

    #area-textos-nossa-historia {
        width: 75%;
        text-align: center;
    }
}
:root{
  /* Paleta de cores ----------------------------------- */
  --agua-marinha: #54c4cf;
  --ametista: #944fc2;
  --branco: #ededee;
  --esmeralda: #6bdb89;
  --preto: #2f3436;
  /* Gradientes ---------------------------------------- */
  --gradiente-branco-ffffff:linear-gradient(180deg, rgba(237,237,238,1) 0%, rgba(255,255,255,1) 100%);
  /* Espacamentos -------------------------------------- */
  --padding-horizontal-p:7vw;
  --padding-horizontal-m:10vw;
  --padding-horizontal-g:13vw;
  --padding-interno-horizontal-p: 3.3vw;
  --padding-vertical-p:5vh;
  --padding-vertical-m:7vh;
  --padding-vertical-g:9vh;
}

*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font: 100% 'Raleway', sans-serif;
  scroll-behavior: smooth;
}

body{
  background-color: var(--branco);
}

#fullpage{
  max-height: 100%;
  max-width: 100%;
}

.inline-flex{
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.titulos{
  font-size: 2em;
  font-weight: 600;
}

.textos{
  font-size: 1.25em;
  font-weight: 400;
}

b{font-weight: 600;}

.container{
  margin: var(--padding-vertical-p) var(--padding-horizontal-p) 0  var(--padding-horizontal-p);
  height: fit-content;
}

#area-linha-curva{
  height: fit-content;
  padding: 0 var(--padding-interno-horizontal-p);
  margin: 0 var(--padding-horizontal-p);
  margin-bottom: -1vh;
  margin-top: -0.65vh;
}

#linha-curva{
  max-width: 100%;
  height: auto;
}

@media (max-width: 639px) {
  *{
    font: 97% 'Raleway', sans-serif;
  }

  :root{
    --padding-horizontal-p:5vw;
    --padding-horizontal-m:8vw;
    --padding-horizontal-g:11vw;
    --padding-interno-horizontal-p: 5vw;
    --padding-vertical-p:2vh;
    --padding-vertical-m:4vh;
    --padding-vertical-g:6vh;
  }
}


#o-que-fazemos{
    padding: 0 0 0 var(--padding-interno-horizontal-p);
    background-color: var(--esmeralda);
    height: fit-content;
    min-height: 45vh;
    display: inline-flex;
    align-items: flex-end;
    background-color: var(--agua-marinha);
    background-image: url(../assets/oquefazemos_background.png);
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    border-radius: 0 5em 0 0;
    color: var(--branco); 
    transition: background-size 1s;
}

#area-textos-o-que-fazemos{
    width: 60%;
    padding: var(--padding-vertical-p) 0 var(--padding-vertical-g) 0;
    text-align: justify;
}

#linha-vertical-o-que-fazemos{
    height: 40vh;
    width: 0.3em;
    margin-right: 1.5em;
    background-color: var(--preto);
    border-radius: 0.3em 0.3em 0 0 ;
    transition: 2s;
}

@media (max-width: 639px){

    #o-que-fazemos {
        background-size: cover;
        background-position: 55% ;
    }

    #o-que-fazemos{
        min-height: 40vh;
    }

    #area-textos-o-que-fazemos{
        width: 75%;
        text-align: justify;
    }
    
    #linha-vertical-o-que-fazemos{
        height: 35vh;
    }

}
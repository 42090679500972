#rodape{
    height: fit-content;
    width: 100%;
    background-color: var(--preto);
    text-align: center;
    padding: var(--padding-vertical-p) var(--padding-horizontal-p) calc(var(--padding-vertical-p) / 2) var(--padding-horizontal-p);
    color:var(--branco);
}

#conteudo-rodape{
    height: fit-content;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

#area-textos-rodape{
    text-align: right;
    color: #686c6e;
}

#area-icones{
    display: inline-flex;
    margin-right: -10px;
}

.icone{
    height: 30px;
    width: fit-content;
    margin-right: 10px;
    fill: var(--branco);
}

#direitos{
    color: #686c6e;
    margin-top:2.5vh
}

#area-textos-rodape a{
    color:#686c6e;
    text-decoration: none;
}

#area-textos-rodape a:hover{
    color:var(--agua-marinha)
}
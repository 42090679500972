#area-servicos{
    width: 100%;
    height: fit-content;
    display: inline-flex;
    justify-content: space-between;
}

#yomo{
    width: 49%;
    height: fit-content;
    margin-bottom: 0px;
}

#yomo-imagem{
    width: 100%;
    height: auto;
}

#servicenow{
    width: 49%;
    height: fit-content;
    margin-bottom: 0px;
}

#servicenow-imagem{
    width: 100%;
    height: auto;
    border-radius: 0 5em 0 0;
}

@media (max-width: 639px){
    #area-servicos{
        display: block;
    }
    
    #servicenow{
        width: 100%;
        padding-top: calc(var(--padding-vertical-p) / 2);
    }

    #servicenow-imagem{
        width: 100%;
        height: auto;
        border-radius: 0;
    }

    
}
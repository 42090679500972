#area-cabecalho{
    width: 100%;

    margin-top: var(--padding-vertical-m);
    padding: 0 var(--padding-horizontal-p) 0 0;
}

#cabecalho{
    min-height: 70px;
    width: 100%;

    padding: 0 var(--padding-interno-horizontal-p) 0 calc(var(--padding-horizontal-p) + var(--padding-interno-horizontal-p));
    align-items: center;
    justify-content: space-between; 

    border-radius: 0 45px 45px 0;
    background-color: var(--preto);
}

#area-logomarca-cabecalho{
    height: 100%;
    align-items: center;
    padding: 10px;
}

#logomarca-cabecalho{
    height: 100%;
    width: auto;
    fill: var(--branco);
}

#cabecalho ul{
    margin-left: -3vw;
}

#cabecalho ul li{
    display: inline-flex;
    margin-left: 3vw;
    justify-content: center;
}

#cabecalho ul li a{
    font-size: 1.25em;
    font-weight: 600;
    color: var(--branco);
    text-decoration: none;
}

#area-botao-cabecalho{
    height: 100%;
    align-items: center;
    padding: 18px;
}

#botao-cabecalho{
    height: 100%;
    width: auto;
    fill: var(--branco);
}

#area-menu-dropdown{
    height: 0;
    opacity: 0;
    padding: 0;
    transition: 1s;
}

#divtest{
    height: 100px;
    max-width: 100%;
    background-color: palevioletred;
    margin:var(--padding-vertical-p) var(--padding-horizontal-p);
}

@media (max-width: 639px) {
    #cabecalho ul{
        margin-top: -10px;
    }
    
    #cabecalho ul li{
        display: flex;
        margin-left: 0;
        margin-bottom: 10px;
    }
    
}